<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light"
          :show="dismissCountDown"
          dismissible
          fade
          variant="success"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="col-6">
                <p class="card-title-desc">ข้อมูลทั่วไป</p>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="col-md-4 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-md-9 col-sm-8 col-9">
                          <div class="mb-3 position-relative">
                            <label for="roId"> เลือกจากเลขที่ใบแจ้งซ่อม:</label>
                            <!-- disabled="disabled"  -->
                            <multiselect
                              :disabled="
                                this.part.status == 'APV' ||
                                this.part.status == 'SUBMIT'
                              "
                              id="roId"
                              v-model="part.roId"
                              :options="rowRo"
                              style="ts-15"
                              placeholder="ค้นหาเลขที่ใบสั่งซ่อม"
                              label="roCode"
                              :show-labels="false"
                              track-by="roCode"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getRo()"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              :disabled="
                                this.part.status == 'APV' ||
                                this.part.status == 'SUBMIT'
                              "
                              class="btn"
                              variant="info"
                              v-b-modal.modalRo
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-3 col-sm-3">
                      <div class="mb-3 position-relative">
                        <label for="cusId">ชื่อลูกค้า:</label>
                        <!-- disabled="disabled"  -->
                        <multiselect
                          :disabled="
                            this.part.status == 'APV' ||
                            this.part.status == 'SUBMIT'
                          "
                          id="cusId"
                          v-model="part.cusId"
                          :options="rowsCus"
                          style="ts-15"
                          placeholder=""
                          label="nameTh"
                          :show-labels="false"
                          track-by="nameTh"
                          :custom-label="customLabelCus"
                          :loading="loading"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          @search-change="getCustomer()"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-6 col-md-3 col-sm-3">
                      <div class="mb-3 position-relative">
                        <label for="vehicleId">เลขทะเบียน:</label>

                        <multiselect
                          :disabled="
                            this.part.status == 'APV' ||
                            this.part.status == 'SUBMIT'
                          "
                          id="vehicleId"
                          :options="rowsVehi"
                          v-model="part.vehicleId"
                          label="licensePlate"
                          :show-labels="false"
                          track-by="licensePlate"
                          :loading="loading"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          @search-change="getDataVehicles()"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-3">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="reqDate"> วันที่แจ้งซ่อม:</label>

                        <date-picker
                          :disabled="
                            this.part.status == 'APV' ||
                            this.part.status == 'SUBMIT'
                          "
                          v-model="part.reqDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                          :class="{
                            'is-invalid': submitform && $v.part.reqDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.part.reqDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.part.reqDate.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-2 col-sm-3">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="dcPer">ส่วนลด(%):</label>

                        <b-form-input
                          :disabled="
                            this.part.status == 'APV' ||
                            this.part.status == 'SUBMIT'
                          "
                          id="dcPer"
                          v-model="reqDcPer"
                          type="number"
                          class="form-control text-end"
                          value="Otto"
                          @input="calTotal()"
                          :class="{
                            'is-invalid': submitform && $v.reqDcPer.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.reqDcPer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.reqDcPer.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-2 col-sm-3">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="vatPer"> ภาษีมูลค่าเพิ่ม(%):</label>
                        <!-- disabled="disabled"  -->
                        <b-form-input
                          :disabled="
                            this.part.status == 'APV' ||
                            this.part.status == 'SUBMIT'
                          "
                          id="vatPer"
                          v-model="part.vatPer"
                          type="number"
                          class="form-control text-end"
                          value="Otto"
                          :class="{
                            'is-invalid': submitform && $v.part.vatPer.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.part.vatPer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.part.vatPer.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                      <div class="position-relative">
                        <label for="note">หมายเหตุ:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          :disabled="
                            this.part.status == 'APV' ||
                            this.part.status == 'SUBMIT'
                          "
                          v-model="part.note"
                          class="form-control"
                          name="textarea"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mb-4 mt-3">
                      <div class="col text-end ms-1">
                        <button
                          :disabled="
                            this.part.status == 'APV' ||
                            this.part.status == 'SUBMIT'
                          "
                          type="submit"
                          class="btn btn-info ms-1"
                          @click="updateReq()"
                        >
                          บันทึกการแก้ไขข้อมูล
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการอะไหล่</p>
                </div>
                <div class="col-6">
                  <p
                    class="badge bg-success font-size-14 float-end"
                    v-if="this.part.status === 'APV'"
                  >
                    เบิกอะไหล่แล้ว
                  </p>
                  <p
                    class="badge bg-info font-size-14 float-end"
                    v-if="this.part.status === 'SUBMIT'"
                  >
                    รออนุมัติ
                  </p>
                  <p
                    class="badge bg-warning font-size-14 float-end"
                    v-if="this.part.status === 'DFT'"
                  >
                    ร่าง
                  </p>
                  <p
                    class="badge bg-danger font-size-14 float-end"
                    v-if="this.part.status === 'CAN'"
                  >
                    ยกเลิกแล้ว
                  </p>
                </div>
                <!-- <div class="col-12">
                  <code>** หมายเหตุ : กรณีแก้ไขข้อมูลอะไหล่ เช่น ราคา,จำนวน,ส่วนลด หลังแก้ไขเสร็จให้กดปุ่ม Enter เพื่อบันทึกข้อมูล</code>
                </div> -->
              </div>
              <!-- Table -->
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">รหัสอะไหล่</th>
                      <th scope="col">ชื่ออะไหล่</th>
                      <th scope="col">ราคา/หน่วย</th>
                      <th scope="col">จำนวน</th>
                      <th scope="col">รวม</th>
                      <th scope="col">ส่วนลด(%)</th>
                      <th scope="col">ส่วนลดยอด</th>
                      <th scope="col">หลังลด</th>
                      <!-- <th scope="col" class="text-end">vat (%)</th>
                    <th scope="col" class="text-end">vat</th> -->
                      <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody v-if="this.rowReqParts.length > 0">
                    <tr v-for="(partItem, index) in rowReqParts" :key="index">
                      <th scope="row">{{ index + 1 }}</th>

                      <td>{{ partItem.partCode }}</td>
                      <td>{{ partItem.partNameTh }}</td>
                      <td class="">
                        <input
                          :disabled="
                            part.status == 'APV' || part.status == 'SUBMIT'
                          "
                          v-model="partItem.pricePerOne"
                          class="form-control text-end number"
                          @keyup.enter="updatePart(partItem)"
                          @input="calculateReq(partItem)"
                          @change="calculateReq(partItem)"
                        />
                      </td>
                      <td class="">
                        <input
                          :disabled="
                            part.status == 'APV' || part.status == 'SUBMIT'
                          "
                          type="number"
                          v-model="partItem.amount"
                          class="form-control text-end Dc"
                          @keyup.enter="updatePart(partItem)"
                          @input="calculateReq(partItem)"
                          @change="updatePart(partItem)"
                        />
                      </td>
                      <td class="text-end">
                        <input
                          disabled
                          v-model="partItem.totalPrice"
                          class="form-control text-end number"
                        />
                      </td>
                      <td class="">
                        <input
                          :disabled="
                            part.status == 'APV' || part.status == 'SUBMIT'
                          "
                          v-model="partItem.dcPer"
                          class="form-control text-end Dc"
                          @keyup.enter="updatePart(partItem)"
                          @input="calculateReq(partItem)"
                          @change="calculateReq(partItem)"
                        />
                      </td>
                      <td class="text-end" @change="calculateReq(partItem)">
                        <input
                          disabled
                          v-model="partItem.dcPrice"
                          class="form-control text-end number"
                        />
                      </td>
                      <td class="text-end" @change="calculateReq(partItem)">
                        <input
                          disabled
                          v-model="partItem.netPrice"
                          class="form-control text-end number"
                        />
                      </td>

                      <td v-if="part.status == 'DFT'">
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alert(partItem, index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr />
              <!-- search-->
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="col-6">
                <p class="card-title-desc">ค้นหารายการอะไหล่</p>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="row">
                      <span
                        v-if="this.rowReqParts.length === 0"
                        class="text-center font-size-16"
                        >ไม่พบรายการ</span
                      >
                    </div>
                    <hr />
                    <div class="row">
                      <form
                        class="needs-validation"
                        @submit.prevent="searchForm"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3 position-relative">
                              <label for="partsCode"
                                ><code>*</code>
                                ค้นหารหัสอะไหล่,ชื่ออะไหล่:</label
                              >
                              <multiselect
                                :disabled="
                                  this.part.status == 'APV' ||
                                  this.part.status == 'SUBMIT'
                                "
                                id="partsCode"
                                v-model="searchform.partsCode"
                                :options="rowParts"
                                :class="{
                                  'is-invalid': submit,
                                }"
                                label="partId"
                                :custom-label="customLabel"
                                :show-labels="false"
                                track-by="nameTh"
                                @input="showModal(searchform.partsCode)"
                                :loading="loading"
                                :close-on-select="true"
                                :internal-search="true"
                                open-direction="bottom"
                                placeholder="ค้นหาชื่ออะไหล่"
                                @search-change="getParts($event)"
                                @click="setFocus()"
                              >
                              </multiselect>

                              <!-- @ok="handleOk" -->
                            </div>
                          </div>

                          <div></div>
                        </div>
                      </form>
                      <!-- modal -->

                      <b-modal
                        ref="modalAmout"
                        title="ระบุจำนวน"
                        hide-footer
                        size="sm"
                        centered
                        :state="nameState"
                      >
                        <form
                          class="needs-validation"
                          @submit.prevent="modalForm"
                        >
                          <div class="row">
                            <div class="col">
                              <div class="mb-3 position-relative">
                                <label for="amount"
                                  ><code>*</code> จำนวน:</label
                                >
                                <span>({{ this.part.nameTh }})</span>
                                <input
                                  :state="nameState"
                                  id="amount"
                                  ref="focuss"
                                  v-model="part.amount"
                                  type="number"
                                  style="text-align: center"
                                  class="form-control"
                                  value="Otto"
                                  @input="calculateReq(part)"
                                  @change="calculateReq(part)"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.part.amount.$error,
                                  }"
                                  autofocus="true"
                                />
                                <div
                                  v-if="submitted && $v.part.amount.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.part.amount.required">{{
                                    message
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-12">
                              <div class="text-end">
                                <button
                                  @click="postReqPart(part)"
                                  class="btn btn-primary"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </b-modal>
                      <!-- end model -->
                    </div>
                    <hr />
                    <div class="card-body">
                      <h4 class="card-title">
                        <h4>
                          <strong>สรุปยอดการเบิกอะไหล่</strong>
                        </h4>
                      </h4>
                      <hr />
                      <div class="row">
                        <div class="col-md-2 col-sm-3">รายละเอียดการเบิก</div>
                        <div class="col-md-2 col-sm-3 col-6">
                          <div style="display: inline-block">
                            <div class="text-end">
                              ยอดรวมค่าอะไหล่ : <br />
                              ส่วนลดรวม(%) : <br />
                              ส่วนลดรวม(บาท) : <br />
                              จำนวนเงิน : <br />
                              ภาษีรวม(%) : <br />
                              ภาษีรวม(บาท) : <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-4 col-4 text-end">
                          <strong>
                            {{ this.numberFormats(this.reqTotalPrice) }} <br />
                            {{ this.numberFormats(this.reqDcPer) }}<br />
                            {{ this.numberFormats(this.reqDcPrice) }} <br />
                            {{ this.numberFormats(this.reqNetPrice) }} <br />
                            {{ this.numberFormats(this.reqVatPer) }} <br />
                            {{ this.numberFormats(this.reqVatPrice) }}</strong
                          >
                        </div>
                        <div class="col-md-1 col-sm-3 col-2 text-end">
                          บาท<br />
                          %<br />
                          บาท <br />
                          บาท <br />
                          % <br />
                          บาท
                        </div>
                        <!-- มูลค่ารวม -->
                        <div
                          class="
                            col-md-5 col-sm-5
                            bg-dark
                            text-white
                            m-0
                            row
                            align-items-center
                          "
                          style="border-radius:10px;margin:auto;justify "
                        >
                          <div class="row align-items-center m-0">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-3">มูลค่ารวม :</div>
                                <div class="col-6 font-size-24 text-center">
                                  <strong>{{
                                    Number(
                                      this.reqGrandTotalPrice
                                    ).toLocaleString()
                                  }}</strong>
                                </div>
                                <div class="col-3">บาท</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br /><br />
                      <div class="row mb-4 mt-4">
                        <div class="col text-end ms-1">
                          <button
                            :disabled="
                              this.part.status == 'APV' ||
                              this.part.status == 'SUBMIT'
                            "
                            class="btn btn-danger ms-1"
                            @click="alertSubmit()"
                          >
                            ยืนยันการเบิก
                          </button>
                          <p>
                            <code
                              v-if="
                                this.part.status == 'APV' ||
                                this.part.status == 'SUBMIT'
                              "
                              >***
                              ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว</code
                            >
                            <code v-if="this.part.status == 'DFT'"
                              >*** ถ้ายืนยันการเบิกแล้ว
                              จะไม่สามารถแก้ไขข้อมูลได้</code
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card -->
        </b-overlay>
      </div>
    </div>

    <b-modal
      ref="modalRo"
      id="modalRo"
      title="รายการสั่งซ่อม"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่ใบแจ้งซ่อม:
                  <b-form-input
                    v-model="filter.roCode"
                    type="search"
                    placeholder="เลขที่ใบแจ้งซ่อม"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  ชื่อ-นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.customerFullNameTh"
                    type="search"
                    placeholder="ชื่อ-นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  ทะเบียนรถ
                  <b-form-input
                    v-model="filter.licensePlate"
                    type="search"
                    placeholder="ทะเบียนรถ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-md-3 col-sm-3">
                <div class="mb-4 position-relative">
                  <label class="d-inline align-items-center m-2">
                    วันที่สั่งซ่อม:</label
                  >

                  <date-picker
                    v-model="filter.roDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    @input="handleSearch"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
                <!-- <div class="col-md-3 col-sm-7">
                  <div class="mb-3 position-relative">
                    <label for="veh"
                      >เลือก<code>(เจ้าของรถ/ผู้ขับขี่)</code></label
                    >
                    <multiselect
                      id="veh"
                      v-model="veh"
                      :options="optionfieldVeh"
                      track-by="name"
                      label="name"
                      :show-labels="false"
                      @input="showFieldVeh"
                    ></multiselect>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowRo"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <!-- <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template> -->

              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <div class="col">
              หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
              {{ totalRecord }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    @change="handleChangePage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <!-- <p>
              <b-button size="sm" @click="selectAllRowsVeh"
                >เลือกทั้งหมด</b-button
              >
              |
              <b-button size="sm" @click="clearSelectedVeh"
                >ล้างการเลือก</b-button
              >
            </p> -->
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>

    <!-- end col -->

    <!-- table -->

    <!-- end table -->
  </Layout>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  page: {
    title: appConfig.req,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      enabled: false,
      modalShow: false,
      message: "โปรดระบุข้อมูล",
      title: "แก้ไขใบเบิกอะไหล่",
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
        {
          text: appConfig.req,
          href: "/part-requisition",
        },
        {
          text: "แก้ไขใบเบิกอะไหล่",
          active: true,
        },
      ],
      fields: [
        {
          key: "roCode",
          sortable: true,
          label: "เลขที่ใบสั่งซ่อม",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "roDate",
          sortable: true,
          label: "วันที่สั่งซ่อม",
        },

        {
          key: "dcPer",
          sortable: true,
          label: "ส่วนลด",
        },
        {
          key: "vatPer",
          sortable: true,
          label: "ภาษี",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      selectRowRo: [],
      branchIdRo: "",
      loading: false,
      rowsCus: [],
      rowsVehi: [],
      localDataBranchArray: [],
      svId: this.$route.params.svId,
      localDataBranchUser: [],
      nameState: null,
      selectMode: "single",
      selected: [],
      isLoading: "",
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      rowTest: [],
      totalRows: 1,
      rowParts: [],
      pageOptions: [5, 10, 25, 50, 100],
      filter: {
        roCode: "",
        customerFullNameTh: "",
        licensePlate: "",
        roDate: "",
      },
      reqId: this.$route.params.reqId,
      roId: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      rowReqParts: [],
      partsCode: [],
      rowRo: [],
      options: [],
      selectedPart: {},
      branchId: "",
      reqTotalPrice: 0, // มูลค่ารวมอะไหล่
      reqDcPer: 0,
      reqDcPrice: 0,
      reqNetPrice: 0,
      reqVatPer: appConfig.vat,
      reqVatPrice: 0,
      reqGrandTotalPrice: 0,
      part: {
        partId: "",
        partCode: "",
        nameTh: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        vatPrice: 0,
        vatPer: appConfig.vat,
        grandTotalPrice: 0, // มูลค่ารวมคิดภาษี
        branchId: "",
        poDate: "",
        vatType: { text: "ภาษีนอก(ยังไม่รวมภาษี)" },
        roId: "",
        cusId: "",
        vehicleId: "",
        reqDate: "",
        note: "",
      },
      searchform: {
        partsCode: "",
      },
      submit: false,
      submitted: false,
      submitform: false,
    };
  },
  validations: {
    part: {
      branchId: {
        required,
      },

      reqDate: {
        required,
      },

      vatPer: {
        required,
      },
      amount: {
        required,
      },
    },

    reqDcPer: { required },

    modelform: {},
  },
  computed: {},

  mounted() {},
  created() {
    this.getReqShow();
    this.getLocalData();
  },
  methods: {
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    isDisabled() {
      return this.part.status == "APV" ? "disabled" : "";
    },
    customLabel({ partCode, nameTh }) {
      return `[${partCode != null ? " " + partCode : ""}] - ${
        nameTh != null ? nameTh : ""
      }`;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchArray = localDataBranchId;
      this.getRo(this.branchId);
    },
    calculateReq(part) {
      let totalPriceCal =
        parseFloat(part.pricePerOne ? part.pricePerOne : 0) *
        parseFloat(part.amount);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal =
        parseFloat(part.totalPrice ? part.totalPrice : 0) *
        parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice =
        parseFloat(part.totalPrice ? part.totalPrice : 0) -
        parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(part.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice ? part.netPrice : 0) +
        parseFloat(part.vatPrice ? part.vatPrice : 0);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }
      // part.totalPrice = this.numberFormats(part.totalPrice);
      // part.dcPrice = this.numberFormats(part.dcPrice);
      // part.netPrice = this.numberFormats(part.netPrice);
      // part.vatPrice = this.numberFormats(part.vatPrice);
      // part.grandTotalPrice = this.numberFormats(part.grandTotalPrice);
      this.calTotal();
    },
    calTotal() {
      let reqTotalPrice,
        reqDcPrice,
        reqNetPrice,
        reqVatPrice,
        reqGrandTotalPrice;

      reqTotalPrice = this.rowReqParts.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice ? item.netPrice : 0);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      this.reqTotalPrice = reqTotalPrice.toFixed(2);
      reqDcPrice =
        parseFloat(this.reqTotalPrice) * parseFloat(this.reqDcPer / 100);
      if (!isNaN(reqDcPrice)) {
        this.reqDcPrice = reqDcPrice.toFixed(2);
      }
      reqNetPrice =
        parseFloat(this.reqTotalPrice) - parseFloat(this.reqDcPrice);
      if (!isNaN(reqNetPrice)) {
        this.reqNetPrice = reqNetPrice.toFixed(2);
      }

      reqVatPrice = this.reqNetPrice * parseFloat(this.reqVatPer / 100);
      if (!isNaN(reqVatPrice)) {
        this.reqVatPrice = reqVatPrice.toFixed(2);
      }

      reqGrandTotalPrice =
        parseFloat(this.reqNetPrice) + parseFloat(this.reqVatPrice);
      if (!isNaN(reqGrandTotalPrice)) {
        this.reqGrandTotalPrice = reqGrandTotalPrice.toFixed(2);
      }
    },
    searchForm() {
      this.$v.$touch();
      this.submit = true;
    },
    modalForm() {
      this.$v.$touch();
      this.submitted = true;
    },
    showModal(option) {
      this.selectedPart = option;
      this.part.partCode = option.partCode;
      this.part.pricePerOne = option.sellPrice ? option.sellPrice : 0;
      this.part.totalPrice = option.sellPrice ? option.sellPrice : 0;
      this.part.netPrice = option.sellPrice ? option.sellPrice : 0;
      this.part.nameTh = option.nameTh;
      this.part.partId = option.partId;
      this.$refs.modalAmout.show(option);
      this.$nextTick(() => {
        this.setFocus();
      });
    },
    setFocus: function () {
      this.$refs.focuss.focus();
    },
    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : ""
      }`;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowRo = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      var roCode = { roCode: this.selected.roCode, roId: this.selected.roId };
      var customerFullNameTh = {
        nameTh: `${
          this.selected.customerNameTh != null
            ? this.selected.customerNameTh
            : ""
        } ${
          this.selected.customerFamilyNameTh != null
            ? this.selected.customerFamilyNameTh
            : ""
        }`,
        cusId: this.selected.cusId,
      };
      var licensePlate = {
        licensePlate: this.selected.licensePlate,
        vehicleId: this.selected.vehicleId,
      };
      var roDate = this.selected.roDate;
      var dcPer = this.selected.dcPer;
      var vatPer = this.selected.vatPer;

      this.part.roId = roCode;
      this.part.cusId = customerFullNameTh;
      this.part.vehicleId = licensePlate;
      this.part.reqDate = roDate;
      this.reqDcPer = dcPer;
      this.part.vatPer = vatPer;

      this.getCustomer();
      this.$refs["modalRo"].hide();

      // this.getData();
    },

    // eslint-disable-next-line no-unused-vars
    modelForm() {
      this.$v.$touch();
      this.submitted = true;
    },

    requiredForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.part.$invalid != true) {
        this.postReq();
      }
    },
    postReq: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/part-requisition/store", {
          branchId: this.part.branchId.branchId,
          roId: this.part.roId.roId,
          cusId: this.part.cusId.cusId,
          vehicleId: this.part.vehicleId.vehicleId,
          reqDate: this.part.reqDate,
          note: this.part.note,
          vatPer: this.part.vatPer,
          dcPer: this.part.dcPer,
        })
        .then((response) => {
          this.reqId = response.data.reqId;
          // this.SuccessPost = false;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getReqPart(this.reqId);
          this.calTotal();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getReqShow(this.reqId);
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    postReqPart: function (part) {
      let arr = [];
      this.rowReqParts.forEach((item) => {
        arr.push(item.partId);
      });
      if (!arr.includes(part.partId)) {
        this.rowReqParts.push(part);
      }
      this.calculateReq(part);
      part.partId = this.selectedPart.partId;
      part.nameTh = this.selectedPart.nameTh;
      part.pricePerOne = this.selectedPart.sellPrice;
      part.totalPrice = parseFloat(this.selectedPart.sellPrice ? this.selectedPart.sellPrice:0) * part.amount;
      part.netPrice;
      const formDataPart = new FormData();
      formDataPart.append("reqId", this.reqId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", part.amount);
      formDataPart.append(
        "pricePerOne",
        part.pricePerOne ? part.pricePerOne : 0
      );
      formDataPart.append("totalPrice", part.totalPrice);
      formDataPart.append("dcPrice", part.dcPrice);
      formDataPart.append("dcPer", part.dcPer);
      formDataPart.append("netPrice", part.netPrice.replace(/,/g, ""));
      formDataPart.append("vatPer", part.vatPer);
      formDataPart.append("vatPrice", part.vatPrice.replace(/,/g, ""));
      formDataPart.append(
        "grandTotalPrice",
        part.grandTotalPrice.replace(/,/g, "")
      );
      formDataPart.append("reqTotalPrice", this.reqTotalPrice);
      formDataPart.append("reqDcPer", this.reqDcPer);
      formDataPart.append("reqDcPrice", this.reqDcPrice);
      formDataPart.append("reqNetPrice", this.reqNetPrice);
      formDataPart.append("reqVatPer", this.reqVatPer);
      formDataPart.append("reqVatPrice", this.reqVatPrice);
      formDataPart.append("reqGrandTotalPrice", this.reqGrandTotalPrice);

      useNetw
        .post("api/part-requisition/part/store", formDataPart)
        .then((response) => {
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          this.showAlert(response.data.message);
          this.searchform.partsCode = "";
          this.$refs["modalAmout"].hide();
          this.getReqShow(this.reqId);
          this.calTotal();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    updatePart(part) {
      this.calTotal();
      useNetw
        .put("api/part-requisition/part/update", {
          reqId: this.reqId,
          partId: part.partId,
          amount: part.amount,
          pricePerOne: part.pricePerOne,
          totalPrice: part.totalPrice.replace(/,/g, ""),
          dcPer: part.dcPer,
          dcPrice: part.dcPrice,
          netPrice: part.netPrice.replace(/,/g, ""),
          vatPer: part.vatPer,
          vatPrice: part.vatPrice.replace(/,/g, ""),
          grandTotalPrice: part.grandTotalPrice.replace(/,/g, ""),
          reqTotalPrice: this.reqTotalPrice,
          reqDcPer: this.reqDcPer,
          reqDcPrice: this.reqDcPrice,
          reqNetPrice: this.reqNetPrice,
          reqVatPer: this.reqVatPer,
          reqVatPrice: this.reqVatPrice,
          reqGrandTotalPrice: this.reqGrandTotalPrice,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          this.getReqShow(this.reqId);
          this.calTotal();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    updateReq() {
      useNetw
        .put("api/part-requisition/update", {
          reqId: this.reqId,
          reqDate: this.part.reqDate,
          roId: this.part.roId.roId,
          cusId: this.part.cusId.cusId,
          vehicleId: this.part.vehicleId.vehicleId,
          note: this.part.note,
          totalPrice: this.reqTotalPrice,
          dcPer: this.reqDcPer,
          dcPrice: this.reqDcPrice,
          netPrice: this.reqNetPrice,
          vatPer: this.reqVatPer,
          vatPrice: this.reqVatPrice,
          grandTotalPrice: this.reqGrandTotalPrice,
        })

        .then((response) => {
          this.getReqShow(this.reqId);
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // this.$router.push({ name: "purchase-order" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getReqShow: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/part-requisition/show", {
          params: {
            reqId: this.reqId,
          },
        })
        .then((response) => {
          // console.log(response);
          this.branchIdReq = response.data.data.branchId;
          this.rowReqShow = response.data.data;
          this.part.status = response.data.data.status;
          this.part.branchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };

          this.part.reqDate = response.data.data.reqDate;
          this.part.roId = { rocode: response.data.data.rocode };
          this.part.roId = {
            roId: response.data.data.roId,
            roCode: response.data.data.roCode,
          };
          this.part.cusId = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.customerNameTh,
            familyNameTh: response.data.data.customerFamilyNameTh,
          };
          this.part.vehicleId = {
            vehicleId: response.data.data.vehicleId,
            licensePlate: response.data.data.licensePlate,
          };
          this.reqDcPer = response.data.data.dcPer;
          this.reqDcPrice = response.data.data.dcPrice;
          this.reqTotalPrice = response.data.data.totalPrice;
          this.reqNetPrice = response.data.data.netPrice;
          this.reqVatPer = response.data.data.vatPer;
          this.reqVatPrice = response.data.data.vatPrice;
          this.reqGrandTotalPrice = response.data.data.grandTotalPrice;
          this.part.note = response.data.data.note;
          this.calTotal();
          this.getReqPart(this.reqId);
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;

          // this.loading = false; //skeleton false
        });
    },
    getReqShowUp: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/part-requisition/show", {
          params: {
            reqId: this.reqId,
          },
        })
        .then((response) => {
          // console.log(response);
          this.rowReqShow = response.data.data;
          this.part.status = response.data.data.status;
          this.part.branchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };

          this.part.reqDate = response.data.data.reqDate;
          this.part.roId = { rocode: response.data.data.rocode };
          this.part.roId = {
            roId: response.data.data.roId,
            roCode: response.data.data.roCode,
          };
          this.part.cusId = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.customerNameTh,
            familyNameTh: response.data.data.customerFamilyNameTh,
          };
          this.part.vehicleId = {
            vehicleId: response.data.data.vehicleId,
            licensePlate: response.data.data.licensePlate,
          };
          this.reqDcPer = response.data.data.dcPer;
          this.reqDcPrice = response.data.data.dcPrice;
          this.reqTotalPrice = response.data.data.totalPrice;
          this.reqNetPrice = response.data.data.netPrice;
          this.reqVatPer = response.data.data.vatPer;
          this.reqVatPrice = response.data.data.vatPrice;
          this.reqGrandTotalPrice = response.data.data.grandTotalPrice;
          this.part.note = response.data.data.note;
          this.calTotal();
          this.getReqPart(this.reqId);
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;

          // this.loading = false; //skeleton false
        });
    },
    getDataVehicles: function () {
      this.isLoading = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.localDataBranchArray,
          },
        })
        .then((response) => {
          this.rowsVehi = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getRo: function (branchId) {
      this.loading = true;
      this.branchIdRo = branchId;
      useNetw
        .get("api/part-requisition/repair-orders", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchIdRo,
            roCode: this.filter.roCode,
            customerFullNameTh: this.filter.customerFullNameTh,
            licensePlate: this.filter.licensePlate,
            roDate: this.filter.roDate,
          },
        })
        .then((response) => {
          this.rowRo = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
          // this.overlayFlag = false;
        });
    },
    getCustomer: function () {
      this.isLoading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.localDataBranchArray,
          },
        })
        .then((response) => {
          this.rowsCus = response.data.data;
          // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getParts: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: [this.branchIdReq],
            nameTh: event,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getReqPart: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-requisition/parts", {
          params: {
            reqId: this.reqId,
          },
        })
        .then((response) => {
          this.rowReqParts = response.data.data;
          this.calTotal();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;

          this.loading = false; //skeleton false
        });
    },
    deleteReqPart: function (rowReqItem, index) {
      this.rowReqParts.splice(index, 1);
      this.calTotal(this.rowReqParts);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/part-requisition/part/delete", {
          data: {
            partId: rowReqItem.partId,
            reqId: this.reqId,
            reqTotalPrice: this.reqTotalPrice,
            reqDcPer: this.reqDcPer,
            reqDcPrice: (this.reqDcPrice =
              parseFloat(this.reqTotalPrice) * parseFloat(this.reqDcPer / 100)),
            reqNetPrice:
              (this.reqNetPrice = this.reqNetPrice =
                parseFloat(this.reqTotalPrice - this.reqDcPrice)),
            reqVatPer: this.reqVatPer,
            reqVatPrice: (this.reqVatPrice =
              this.reqNetPrice * parseFloat(this.reqVatPer / 100)),
            reqGrandTotalPrice:
              (this.reqGrandTotalPrice = this.reqGrandTotalPrice =
                parseFloat(this.reqNetPrice) + parseFloat(this.reqVatPrice)),
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getReqShow(this.reqId);
          this.calTotal();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;

          this.loading = false; //skeleton false
        });
    },
    putSubmit() {
      useNetw
        .put("api/part-requisition/submit", {
          reqId: this.reqId,
        })

        .then((response) => {
          this.calTotal();
          this.getReqPart(this.reqId);

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getReqShow(this.reqId);
          this.$router.push({ name: "part-requisition" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    alert(rowReqItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteReqPart(rowReqItem, index);
          }
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันการเบิกหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    handleSearch() {
      this.getRo(this.branchIdRo);
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getRo(this.branchIdRo);
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getRo(this.branchIdRo);
    },
  },
  middleware: "authentication",
};
</script>


<style lang="scss" scoped>
.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 100px;
  text-align: right;
}
.Dc {
  width: 80px;
}
.action {
  width: 40px;
}
.numberP {
  width: 140px;
  text-align: right;
}
.price {
  width: 170px;
  text-align: right;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>

